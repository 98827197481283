'use client'

import { useEffect } from 'react'

import { Button } from '@sikt/sds-button'
import { useTranslations } from 'next-intl'

import { useLogger } from '@/utils/logger/useLogger'

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const t = useTranslations('common.app.Error')
  const { error: logError } = useLogger()

  useEffect(() => {
    logError(`Nextjs error caught: ${error.message}`)
    console.error(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <div>
      <p>{t('body')}</p>
      <p>{error.message}</p>
      <br />
      <Button
        variant="strong"
        onClick={() => {
          reset()
        }}
      >
        {t('retry')}
      </Button>
    </div>
  )
}
